'use client'

import { Children, forwardRef } from 'react'
import * as styles from './Button.css'
import Link, { type LinkProps } from 'next/link'
import { Button as BaseButton } from '@ariakit/react'
import { type RecipeVariants } from '@vanilla-extract/recipes'
import { type LucideIcon } from 'lucide-react'

type ButtonVariants = RecipeVariants<typeof styles.buttonRecipe>

export type Props = {
  as?: React.ElementType
  /** When `href` is present a `a` tag is rendered together with Next.js's Link component */
  href?: LinkProps['href']
  /** If only an icon is provided and no children, then `square` is set to `true` automatically */
  icon?: LucideIcon
  /** Same props as Next.js's Link component */
  linkProps?: Omit<LinkProps, 'href'>
} & ButtonVariants & JSX.IntrinsicElements['button'] & Omit<JSX.IntrinsicElements['a'], 'href'>

type Ref = HTMLButtonElement

const Button = forwardRef<Ref, Props>(function Button ({
  children,
  className = '',
  size = 'medium',
  variant = 'raised',
  circular = false,
  square = false,
  icon: Icon,
  href,
  linkProps = {},
  as: As = 'button',
  ...props
}, ref) {
  const isIconOnly = Icon !== undefined && Children.toArray(children).length === 0
  square = isIconOnly || square

  const isExternal = typeof href === 'string' && href.startsWith('https://')
  if (isExternal) {
    props.rel = 'noopener'
    props.target = '_blank'
  }
  const button = (
    <BaseButton
      render={href !== undefined ? <Link {...linkProps} href={href} /> : <As />}
      // href={href}
      className={`${styles.buttonRecipe({ size, variant, circular, square })} ${className}`}
      {...props}
      ref={ref}
    >
      {Icon !== undefined && <Icon size={20} />}
      {children}
    </BaseButton>
  )

  return button
})

export default Button
