import { Component, type ReactNode } from 'react'
import { Stack } from '@christiankaindl/lyts'

// React only supports error boundaries with class-components for now
export default class ErrorBoundary extends Component<{ title?: string, dataInfo?: () => string, children: ReactNode }> {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError () {
    return { hasError: true }
  }

  componentDidCatch (error, info) {
    console.log('[Error] A component crashed', error, info)
  }

  render () {
    const {
      title = 'Error: Ein Fehler ist aufgetreten',
      dataInfo,
    } = this.props

    // @ts-expect-error React ErrorBoundary types are whack, don't know how to type correctly
    if (this.state.hasError) {
      return (
        <Stack>
          {title}
          {dataInfo && (
            <pre>
              {dataInfo()}
            </pre>
          )}
        </Stack>
      )
    }

    return this.props.children
  }
}
